<template>
    <div class="textarea-group">
        <h3 class="title">{{ title }}</h3>
        <el-input
            v-model="textarea"
            type="textarea"
            :rows="5"
            :placeholder="placeholder"
            maxlength="300"
            show-word-limit
            @input="changeTextarea"
        >
        </el-input>
        <div class="screenshots-group">
            <div>
                <div class="title-group">
                    <div class="title-des">
                        <h3 class="screenshot-title">{{ screenshotTitle }}</h3>
                        <span>最多3张</span>
                    </div>
                    <div class="manipulate" @click="cropEv">
                        <img
                            :src="crop"
                            alt="crop"
                            class="icon-crop"
                        >
                        <span class="crop-text">截图</span>
                    </div>
                </div>
                <div class="screenshot">

                </div>
            </div>
            <div class="screenshots-manipulate">
                <div class="img-wrapper">
                    <div
                        v-for="(item, index) in imgList"
                        :key="index"
                        class="img-group"
                    >
                        <el-image
                            v-if="item.base64"
                            :src="item.base64"
                            alt="img"
                            class="img-item"
                            :preview-src-list="srcList">
                        </el-image>
                        <div class="mask-wrapper">
                            <img
                                v-if="item.uploadType"
                                :src="delImg"
                                alt="del"
                                class="del"
                                @click="del(index)"
                            >
                            <span class="upload" v-else-if="!item.uploadType && !item.url">
                                正在上传中
                            </span>
                            <span class="upload-error" v-else-if="!item.uploadType && item.url === 'err'">
                                <p>上传失败</p>
                                <p>点击重试</p>
                            </span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import ScreenShot from "js-web-screen-shot";
import { uploadFile } from '../https/index.js'
export default {
    name: "TextareaGroup",
    props: {
        title: {
            type: String,
            default : () => {
                return '问题描述'
            }
        },
        screenshotTitle: {
            type: String,
            default : () => {
                return '相关问题截图'
            }
        },
        placeholder: {
            type: String,
            default: () => {
                return 'Hi，请输入您对产品的建议或反馈吧~描述越详细越有助于问 题更快被解决哦。'
            }
        },
        agencyApi: String
    },
    data () {
        return {
            textarea: '',
            imgList: [],
            urlUnoccupied: true
        }
    },
    computed: {
        crop () {
            return require('../img/crop.png')
        },
        upload () {
            return require('../img/upload.png')
        },
        delImg () {
            return require('../img/del.png')
        },
        srcList () {
            return this.imgList.map(item => {
                return item.base64;
            })
        },
    },
    methods: {
        changeTextarea () {
            this.$emit('changeTextarea', this.textarea)
        },
        cropEv () {
            let _this = this;

            if (this.imgList.length >= 3) {
                this.$message.info('截图最多上传三张～')
                return;
            };
            _this.$emit('drawerChange');

            // 截图确认按钮回调函数
            const callback = ({base64})=>{
                setTimeout(function(){
                    _this.$emit('drawerChange');
                    console.log('set输出2')
                    _this.finishCrop(base64)
                },200)
            }
            // 截图取消时的回调函数
            const closeFn = ()=> {
                _this.$emit('drawerChange');
                console.log("截图窗口关闭");
            }
            const cancelFn = () => {
                _this.$emit('drawerChange');
                console.log("用户未授权或不支持");
            }

            new ScreenShot({
                enableWebRtc: true,
                level: 999,
                completeCallback: callback,
                closeCallback: closeFn,
                cancelCallback: cancelFn,
            });
        },
        async finishCrop (base64) {
            const o = {
                id: Date.now(),
                uploadType: false,
                base64,
                url: ''
            }
            this.imgList.push(o)
            this.uploadEv();
        },
        async uploadEv () {
            const notUploaded = this.getNotUploaded();
            console.log(notUploaded,'uploadEv')
            for (const item of notUploaded) {

                let formData =  this.formattingData(item);
                let url = await this.uploadFileSend(formData);

                if(this.checkUrlLegitimacy(url)) {
                    item.uploadType = true;
                    item.url = url.filePath;
                } else {
                    item.uploadType = false;
                    item.url = 'err';
                }
            }

            this.checkUrlUnoccupied()
            this.$emit('setFeedPicture', this.imgList)
        },
        // 检测返回图片是否合法
        checkUrlLegitimacy (url) {
            return url.fileId && url.filePath
        },
        getNotUploaded () {
            return this.imgList.filter(item => !item.uploadType)
        },
        formattingData (item) {
            const name = `${item.id || '截图'}.jpg`;

            let formData = new FormData();
            let arr = item.base64.split(',');
            let bstr = atob(arr[1]);
            let n = bstr.length;
            let u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            };

            formData.append('file', new File([u8arr], name, { type: 'image/png' }))

            return formData;
        },
        uploadFileSend (formData) {
            return new Promise( async (reslove, reject) => {
                await uploadFile(formData, this.agencyApi).then((res) => {
                    res.code == '200' ?  reslove(res.data) : reject(res.msg);
                }).catch((err) => {
                    console.log(err, 'err')
                })
            })
        },
        del (idx) {
            this.imgList.splice(idx, 1)
            console.log(this.imgList,'this.imgList')
            this.$emit('setFeedPicture', this.imgList)
        },
        checkImgList () {
            const notUploaded = this.imgList.filter(item => !item.uploadType);
            console.log(notUploaded,'notUploaded')
            this.$emit('notUploadedImg', notUploaded.length)
        },
        checkUrlUnoccupied () {
            let notUploadedFail = this.getNotUploaded();
            if (notUploadedFail.length > 0) {
                this.urlUnoccupied = true;
            } else {
                this.urlUnoccupied = false;
            }
        }
    },
    watch: {
        imgList: {
            handler () {
                this.checkImgList()
            },
            deep: true,
            immediate: true
        }
    }
}
</script>
<style lang="scss" scoped>
.textarea-group {
    box-sizing: border-box;
    padding: 10px 26px 0px 20px;
    border-radius: 4px;
    overflow: hidden;
    .title {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        padding-bottom: 9px;
    }
    /deep/ .el-textarea {
        textarea::placeholder {
            font-family: "微软雅黑", sans-serif;
        }
        textarea {
            &:not(:placeholder-shown) {
                border: 1px solid #D7DBE0;
                background-color: #FFFFFF;
            }
            &:not(:placeholder-shown) + span{
                background-color: #FFFFFF;
            }
        }
    }
    .screenshots-group {
        box-sizing: border-box;
        padding-top: 16px;
        .title-group {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            .title-des {
                display: flex;
                .screenshot-title {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                }

                span {
                    margin-left: 10px;
                    padding-top: 2px;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #A2A2A2;
                }
            }

            .manipulate {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                box-sizing: border-box;
                cursor: pointer;
                img {
                    width: 13px;
                    height: 13px;
                    display: block;
                }
                .icon-crop {
                    width: 13px !important;
                    height: 13px !important;
                }
                .crop-text {
                    margin-left: 5px;
                    font-size: 12px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #3D7FFF;
                }
                .icon-upload {
                    margin-left: 32px;
                }
            }
        }
        .screenshots-manipulate {
            display: flex;
            min-height: 30px;
            padding-top: 7px;
            .img-wrapper {
                display: flex;
                flex-wrap: wrap;
                .img-group {
                    width: 70px;
                    height: 70px;
                    margin-right: 10px;
                    position: relative;
                    border-radius: 10px;
                    overflow: hidden;
                    .img-item {
                        width: 70px;
                    }
                    .mask-wrapper {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        height: 27px;
                        background-color: rgba(0, 0, 0, 0.46);
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        .del {
                            width: 14px;
                            height: 16px;
                        }
                        .upload {
                            font-size: 12px;
                            color: #FFFFFF;
                        }
                        .upload-error {
                            font-size: 12px;
                            color: #FFFFFF;
                        }
                    }

                }
            }
        }
    }
}
</style>
