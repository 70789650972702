import axios from 'axios';
function uploadFile (params, baseUrl) {
    return axios({
        url: baseUrl + '/log/common/uploadFile',
        method: 'post',
        data: params,
      }).then((res) => {
        if (res.status == '200') {
          return res.data;
        }
      }).catch((err) => {
        throw err;
      })
}

function saveFeedBack (params, baseUrl) {
    return axios({
        url: baseUrl + '/feed/common/saveFeedBack',
        method: 'post',
        data: params,
    }).then((res) => {
        if (res.status == '200') {
            return res.data;
        }
    }).catch((err) => {
        throw err;
    })
}

function getListByCondition (params, baseUrl) {
    return axios({
        url: baseUrl + '/feed/feedBack/listByCondition',
        method: 'post',
        data: params,
    }).then((res) => {
        if (res.status == '200') {
            return res.data;
        }
    }).catch((err) => {
        throw err;
    })
}

function getDetailInfo (params, baseUrl) {
    return axios({
        url: baseUrl + '/feed/feedBack/info',
        method: 'post',
        data: params,
    }).then((res) => {
        if (res.status == '200') {
            return res.data;
        }
    }).catch((err) => {
        throw err;
    })
}

function getUnReadNum(params, baseUrl) {
    return axios({
        url: baseUrl + '/feed/feedBack/getUnReadNum',
        method: 'post',
        data: params,
    }).then((res) => {
        if (res.status == '200') {
            return res.data;
        }
    }).catch((err) => {
        throw err;
    })
}

function updateReadStatus(params, baseUrl) {
    return axios({
        url: baseUrl + '/feed/feedBack/updateReadStatus',
        method: 'post',
        data: params,
    }).then((res) => {
        if (res.status == '200') {
            return res.data;
        }
    }).catch((err) => {
        throw err;
    })
}

export {
    uploadFile,
    saveFeedBack,
    getListByCondition,
    getDetailInfo,
    getUnReadNum,
    updateReadStatus
}
