<template>
    <div class="start-group">
        <span class="start-title">{{ title }}</span>
        <el-rate
            v-model="startValue"
            @change="changeStatus"
            :colors="colors"
            void-icon-class="el-icon-star-on"
            void-color="#E4E4E4"></el-rate>
        <span class="status">{{ startStatus }}</span>
    </div>
</template>
<script>
export default {
    name: "StarGroup",
    props: {
        title: {
            type: String,
            default : () => {
                return '您的使用体验:'
            }
        }
    },
    data () {
        return {
            start: [
                {
                    id: 4,
                    sel: true,
                },
                {
                    id: 3,
                    sel: true,
                },
                {
                    id: 2,
                    sel: true,
                },
                {
                    id: 1,
                    sel: true,
                },
                {
                    id: 0,
                    sel: true,
                }
            ],
            startValue: 5,
            colors: ['#EFD442', '#EFD442', '#EFD442', '#EFD442', '#EFD442']
        }
    },
    computed: {
        startStatus () {
            // const checkedNum = this.start.filter(item => item.sel);

            switch (this.startValue) {
                case 1:
                    return '非常不满意';
                case 2:
                    return '不满意';
                case 3:
                    return '一般';
                case 4:
                    return '满意';
                case 5:
                    return '非常满意'
            }
        }
    },
    methods: {
        changeStatus () {
            this.$emit('starStatus', this.start[this.startValue - 1].id)
        }
    }

}
</script>
<style lang="scss" scoped>
@import url('../css/iconfont.css');
    .start-group {
        display: flex;
        align-items: center;
        padding: 10px 26px 0px 20px;
        .start-title {
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-right: 30px;
        }
        .iconfont {
            display: block;
            font-size: 20px;
            color: #EBEBEB;
            margin-right: 20px;
            cursor: pointer;
            &.current {
                color: #EFD442;
            }
        }
        .status {
            height: 30px;
            line-height: 30px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
        }
        /deep/ .el-rate__icon {
            font-size: 30px;
        }
        /deep/ .el-rate {
            height: 30px;
        }
        /deep/ .el-rate__item {
            height: 30px;
            line-height: 30px;
        }
    }
</style>
