<template>
    <div class="success-group">
        <img :src="successImg" alt="success" class="success-img">
        <div class="success-text">
            <p class="des">
                反馈成功
            </p>
            <span class="text">
               {{ ` 您的反馈将是产品优化最重要的声音(${time}秒后自动关闭)` }}
            </span>
        </div>
        <div class="btn-wrapper">
            <el-button class="help" @click="help">仍需帮助</el-button>
            <el-button class="list" @click="checkList">反馈记录</el-button>
        </div>
    </div>
</template>
<script>
export default {
    name: "SuccessGroup",
    computed: {
        successImg () {
            return require('../img/success.png')
        }
    },
    data () {
        return {
            time: 5
        }
    },
    mounted () {
        this.awaitClose()
    },
    methods: {
        help () {
            this.$emit('help')
        },
        checkList () {
            this.$emit('checkList')
        },
        async awaitClose () {
            if (this.time > 0) {
                await this.timeCountDown()
                this.time --;
                this.awaitClose ()
            }
            else {
                this.close()
            }
        },
        close () {
            this.$emit('close')
        },
        timeCountDown (delay = 1000) {
            return new Promise(resolve => {
                setTimeout(resolve, delay)
            })
        },
    }
}
</script>
<style lang="scss" scoped>
    .success-group {
        width: 100%;
        background-color: #FFF;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        align-items: center;
        .success-img {
            width: 123px;
            height: 123px;
            margin-top: 145px;
        }
        .success-text {
            text-align: center;
            .des {
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                line-height: 49px;
            }
            .text {
                margin-top: 12px;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #999999;
            }
        }
        .btn-wrapper {
            margin-top: 37px;
            .help {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                box-sizing: border-box;
                background: #F4F7FF;
                border-radius: 17px;
                border: 1px solid #F4F7FF !important;
                outline: none;
                /deep/ span {
                    color: #3D7FFF;
                }
            }
            .list {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                border: 1px solid #3D7FFF !important;
                outline: none;
                box-sizing: border-box;
                background: #3D7FFF;
                border-radius: 17px;
                /deep/ span {
                    color: #FFFFFF !important;
                }
            }
        }
    }
</style>
