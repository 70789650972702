<template>
    <div class="title">
        <div class="title-group" @click="handlerClickTitle">
            <slot name="back"></slot>
            <h3 class="title-content">{{ title }}</h3>
        </div>
        <slot name="close"></slot>
    </div>
</template>
<script>
export default {
    name: "TitleGroup",
    props: {
        title: {
            type: String,
            default: () => {
                return "意见反馈"
            }
        },
        des: {
            type: String,
            default: () => {
                return "我要反馈"
            }
        }
    },
    methods: {
        handlerClickTitle () {
            this.$emit('handlerClickTitle')
        }
    }
}
</script>
<style lang="scss" scoped>
    .title {
        height: 49px;
        padding: 0px 26px 0px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title-group {
            display: flex;
            align-items: center;
            cursor: pointer;
        }
        .title-content {
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            line-height: 40px;
        }
    }
</style>
