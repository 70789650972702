<template>
    <div class="tab-group">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
                v-for="(item, index) of tabs"
                :key="index"
                :label="item.title"
                :name="item.id"
            >
                <span 
                    slot="label"
                    class="label-text"
                >   
                    {{ item.title }}
                    <span v-if="item.id == 'feedbackRecord' && hasSendBackInfo"
                    class="feedback-record"></span>
                </span>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
export default {
    name: "TabGroup",
    props: {
        curName: {
            type: String
        },
        tabs: {
            type: Array,
            default: () => ([])
        },
        hasSendBackInfo: {
            type: Boolean
        }
    },
    data () {
        return {
            activeName: '',
        }
    },
    created () {
        this.init();
    },
    methods: {
        init () {
            if (this.curName) {
                this.activeName = this.curName;
            } else if (this.tabs.length > 0) {
                this.activeName = this.tabs[0].id;
            }
        },
        handleClick (tab, ev) {
            this.$emit('tabHandlerClick', tab.index)
        }
    },
    watch: {
        curName: {
            handler (newVal) {
                this.activeName = newVal;
            },
            deep: true
        }
    }
}
</script>
<style lang="scss" scoped>
    .tab-group {
        box-sizing: border-box;
        padding: 0 20px;
        border-bottom: 1px solid #ECECEC;
        .label-text {
            position: relative;
        }
        .feedback-record {
            display: block;
            width: 7px;
            height: 7px;
            background: #F84141;
            border-radius: 3px;
            position: absolute;
            top: 0;
            right: -7px;
        }
    }
    .el-tabs {
        /deep/ .el-tabs__header {
            margin: 0;
        }
        /deep/ .el-tabs__nav-wrap::after {
            height: 0px;
        }

        /deep/ .el-tabs__active-bar.is-top {
            width: 22.6px !important;
            left: 16.7px !important;
            height: 3px !important;
        }
    }
</style>
